<template>
  <div>
    <Options 
      @changeStep="ubahStep" 
      @changeMatpel="ubahMatpel" 
      @changeKelas="ubahKelas" 
      @changeJns="ubahJns" :judul="judul" 
      v-if="step == 0"/>
    <QuizDynamic 
      :matpel="matpel" 
      :jns="jns" 
      :judul="judul" 
      :kelas="kelas"  
      v-if="step == 1"/>
  </div>
</template>

<script>

import QuizDynamic from '@/components/QuizDynamic.vue'
import Options from '@/components/Options.vue'

export default {
  name: 'QuizDynamicView', 

  data() {
    return {
      step: 0,
      matpel: null,
      jns: null,
      judul: 'Latihan Berbasis Komputer',
      kelas: null
    }    
  },  

  components: {
    Options,
    QuizDynamic
  }, 

  methods: {
    ubahStep(newstep) {
      this.step = newstep
    }, 

    ubahMatpel(newmatpel) {
      this.matpel = newmatpel
    },

    ubahJns(newjns) {
      this.jns = newjns
    }, 

    ubahKelas(newkelas) {
      this.kelas = newkelas
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {  
  background-image: url("@/assets/background.jpg");  
  background-repeat: no-repeat; 
  background-size: cover; 
  background-position: center center;
  background-attachment: fixed;
}

</style>
