<template>
    <main class="app w-100 p-4 d-flex align-items-center justify-content-center">
        <h1>{{ judul }}</h1>  
        <h4>Pilih Kelas</h4>
        
        <div class="form-group row">                
            <div class="col-12">
                <select class="form-control" v-model="kelas_selected" @change="setKelas" autofocus>
                    <option v-for="option in kelas" :value="option.id" :key="option.id">
                        {{ option.nama }}
                    </option>
                </select>  
                <div v-if="invalid_kelas" class="invalid-feedback d-flex justify-content-center" >
                    <strong>Kelas harus diisi!</strong>
                </div>
            </div>
        </div>

        <h4>Pilih Pelajaran</h4>
        
        <div class="form-group row">                
            <div class="col-12">
                <select class="form-control" v-model="selected" @change="setMatPel">
                    <option v-for="option in pilihan" :value="option.id" :key="option.id">
                        {{ option.category }}
                    </option>
                </select>  
                <div v-if="invalid_cat" class="invalid-feedback d-flex justify-content-center" >
                    <strong>Pelajaran harus diisi!</strong>
                </div>
            </div>
        </div>
        
        <h4 class="mt-3">Pilih Jenis Pertanyaan</h4>

        <div class="form-group row">                
            <div class="col-12">
                <select class="form-control" v-model="jns_selected" @change="setJns">
                    <option v-for="jns in jns_pertanyaan" :value="jns.id" :key="jns.id">
                        {{ jns.text }}
                    </option>
                </select>  
            </div>
        </div>

        <div class="form-group row mt-3"> 
            <div class="col-12">
                <button class="btn btn-danger" @click="startQuiz"><i class="fa fa-hourglass"></i> Mulai Latihan</button>
            </div>   
        </div>

        
    </main>
</template>

<script>
import axios from "axios"

export default {
    name:'Options', 
    props: ["judul"],
    data() {
        return {
            selected: '0',   
            jns_selected: '1',
            kelas_selected: '',         
            pilihan:[],
            kelas:[],
            invalid_cat: false,
            invalid_kelas: false,
            jns_pertanyaan: [                
                 { 'id':'1', 'text':'Pilihan Ganda'}, 
                 { 'id':'2', 'text':'Isian'}
            ]
        }        
    },
    methods: {
        async getOptions() {
            try {                
                const response = await axios.get('category')
                this.pilihan = response.data                                       
            } catch (error) {
                console.log(error)
            }
        },

        async getKelas() {
            try {                
                const response = await axios.get('kelas')
                this.kelas = response.data                                       
            } catch (error) {
                console.log(error)
            }
        },

        startQuiz() {
            if (this.selected > 0 && this.kelas_selected) {
                this.$emit("changeStep", 1)
                this.$emit("changeMatpel", this.selected)
                this.$emit("changeJns", this.jns_selected)
                this.$emit("changeKelas", this.kelas_selected)
            } else {
                if(this.selected == '0'){
                    this.invalid_cat = true
                } else {
                    this.invalid_cat = false
                }

                if(this.kelas_selected == ''){
                    this.invalid_kelas = true
                } else {
                    this.invalid_kelas = false
                } 
            }   
            
        },

        setMatPel(evt) {
            this.selected = evt.target.value                    
        },

        setKelas(evt) {
            this.kelas_selected = evt.target.value                    
        },

        setJns(evt) {
            this.jns_selected = evt.target.value                    
        }
    },

    created() {
        this.getOptions()  
        this.getKelas()  
    }
}
</script>

<style scoped>

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;  
  min-height: 100vh;
  min-width: 100vh;
}

h1,h4 {
    color:#fff;
}
</style>