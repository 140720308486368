<template>
    <main class="app w-100 p-4 d-flex align-items-center justify-content-center">
        <h1>{{ judul }}</h1>
        <h4>{{ nama_matpel }} {{ nama_kelas }}</h4>
        <section class="quiz" v-if="!quizCompleted">
            <div class="quiz-info">
                <span class="question" v-html="currentQuestion.question"></span>
                <span class="score">{{ (index_question+1) }}/{{ questions.length }}</span>
            </div>

            <div class="options" v-if="jns == 1">
                <label v-for="(option, index) in currentQuestion.options"
                    :id="alphas[index]"                    
                    :key="index"
                    :class="`option ${
                        currentQuestion.selected == alphas[index] 
                        ? alphas[index] == currentQuestion.answer
                            ? 'correct'
                              : 'wrong'
                        : ''
                    } ${ 
                        currentQuestion.selected != null && index != currentQuestion.selected
                        ? 'disabled'
                            :''
                    }`">
                    <input type="radio" 
                        :name="currentQuestion.index" 
                        :value="alphas[index]"
                        v-model="currentQuestion.selected"
                        :disabled="currentQuestion.selected != null"
                        @change="setAnswer">
                        <span v-html="option"></span>
                </label>
            </div>

            <div class="options" v-else>
                <input type="text" v-model="isian" class="form-control" :disabled="courses.length == 1" autofocus />
                <input type="text" v-for="(course, index) in courses" :key="index" v-model="course.benar" :id="index" class="form-control mt-2 option correctblink" disabled />
                
            </div>

            <button
                @click="nextQuestion"
                :disabled="currentQuestion.selected == null && jns == '1'"
                >
                {{
                    index_question == questions.length - 1 
                    ? 'Selesai'
                        : currentQuestion.selected == null
                          ? jns == '1'
                              ? 'Pilih salah satu'
                                : 'Selanjutnya'
                          : 'Selanjutnya'
                }}
            </button>
        </section>

        <section class="quiz" v-else>
            <h2>Latihan sudah selesai</h2>
            <p>{{ score }} Benar dari {{ questions.length }} Soal</p>
            <p>Nilai anda adalah {{ (score/questions.length * 100).toFixed(0) }}</p>
            <button @click="reset">Ulangi Latihan</button>
        </section>
    </main>
</template>

<script>
import axios from "axios"

export default {
    name: 'QuizDynamic',
    props: ["matpel", "judul", "jns", "kelas"],
    data() {
        return {            
            questions: [],
            pilihan: [],
            quizCompleted: false,
            currentQuestion: [],
            score: 0,
            index_question:0,
            alphas:['a', 'b', 'c', 'd'],
            nama_matpel:'',
            nama_kelas:'',
            isian:'',           
            courses: [],
        }
    },

    methods: {

        async getQuestions() {
            try {                
                const response = await axios.get(`quiz/${this.matpel}/${this.jns}/${this.kelas}`)                
                this.questions = response.data
                this.currentQuestion = this.questions[0]                           
            } catch (error) {
                console.log(error)
            }
        },

        async getOptions() {
            try {                
                const response = await axios.get(`category`)
                this.pilihan = response.data   
                
                const arr_temp = this.pilihan.find(pilih => pilih.id === this.matpel)
                this.nama_matpel = arr_temp.category

                const response2 = await axios.get(`kelas`)                
                const arr_temp2 = response2.data.find(kls => kls.id === this.kelas)
                this.nama_kelas = arr_temp2.nama
                
            } catch (error) {
                console.log(error)
            }
        },        

        setAnswer(evt) {
            if (evt.target.value == this.currentQuestion.answer) {
                this.score++;
            } else {
              let el = document.getElementById(this.currentQuestion.answer)
              // document.querySelector(".example");
              el.className = "option correctblink"              
            }

            evt.target.value = null            
        }, 

        nextQuestion() {
            if (this.jns === '2') {              
              if (this.isian.toLowerCase().trim() == this.currentQuestion.answer.toLowerCase().trim()) {
                this.score++;
                this.isian = ''
              } else {
                if (this.courses.length == 0) {
                  this.addMore()
                  this.index_question--
                } else {                  
                  this.remove(0)
                  this.isian = ''
                  this.courses.length == 0
                }
              }
            }

            if (this.index_question < this.questions.length - 1) {
                this.index_question++
                this.currentQuestion = this.questions[this.index_question]
            } else {
                this.quizCompleted = true
            }
        }, 

        addMore() {
          this.courses.push({
            benar: this.currentQuestion.answer.toLowerCase().trim(),
          });          
        },

        remove(index) {
          this.courses.splice(index, 1);
        },

        reset() {
            location.reload()
        }
    },

    created() {  
      this.getQuestions()        
    },

    mounted() {
      this.getOptions()     
      
    }

    
}
</script>

<style scoped>
* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #271c36;
  color: #fff;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  min-width: 100vh;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color:#fff;
}

h4 {
  color:#fff;
  margin-bottom: 1rem;
}

.quiz {
  background-color: #382a4b;
  padding: 1rem;
  width: 100%;
  max-width: 640px;
  border-radius: 0.5em;
  text-align: center;
}

.quiz h2 {
    color: #fff;
}

.quiz-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.quiz-info .question {
  color: #fff;
  font-size: 1.25rem;
  text-align: left;
}

.quiz-info .score {
  color: #8f8f8f;
  font-size: 1.25rem;
}

.options {
  margin-bottom: 1rem;
}

.option {
  display: block;
  padding: 1rem;
  background-color: #271C36;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  color:#fff;
}

.option:hover {
  background-color: #2d213f;
}

.option.correct {
  background-color: #2eec7d;
  
}

.option.correctblink {
  background-color: #2eec7d;
  animation: blink 1.5s linear infinite;
}

@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
}

.option.wrong {
  background-color: #ff5a5f;
}

.option:last-of-type {
  margin-bottom: 0;
}

.option.disabled {
  opacity: 0.5;
}

.option input {
  display: none;
}

button {
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;

  padding: 0.5rem 1rem;
  background-color: #2cce7d;
  color: #2d213f;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.25rem;
  border-radius: 0.5rem;  
}

button.disabled {
  opacity: 0.5;
}

.quiz button {
  margin-top: 1rem;  
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

p {
  color: #8f8f8f;
  font-size: 1.25rem;
  text-align: center;
}
</style>